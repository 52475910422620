import React, { useEffect, useState } from "react";
import ExchengeForm from "../components/ExchengeForm";
import HomeHistorique from "../components/HomeHistorique";
import ReserveCard from "../components/ReserveCard";
import { useSelector } from "react-redux";
import CommentCard from "../components/CommentCard";
import { NavLink } from "react-router-dom";
import axios from "axios";
import ActivityIndicator from "../components/ActivityIndicator";
import Pagination from "../components/Pagination";
const Home = () => {
  const currencyData = useSelector((state) => state.currencyReducer);
  const commentData = useSelector((state) => state.commentReducer);
  const [historique, setHistorique] = useState([]);
  const [historyIsLoading, setHistoryIsLoading] = useState(true);

  const [items, setItems] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);

  const handleTransactionsFetch = async () => {
    await axios
      .get(`${process.env.REACT_APP_SERVER}/api/exchange/`)
      .then((res) => {
        setHistoryIsLoading(false);
        if (res.data.success) {
          setHistorique(res.data.exchange);
        } else {
          setHistorique([]);
        }
      })
      .catch((err) => {
        setHistoryIsLoading(false);
        setHistorique([]);
        console.log(err);
      });
  };

  useEffect(() => {
    document.title = "Vaancoin - Currencies Exchanger";
    document.description =
      "VaanCoin est une structure spécialisée dans les produits monétiques (carte prépayée ect) et vente de crypto-monnaie.";
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
    handleTransactionsFetch();
  }, []);

  return (
    <div className="page-content">
      <div className="home-header">
        <ExchengeForm />
      </div>
      <div className="home-content">
        {currencyData.length > 0 && (
          <div className="w-100">
            <h3 className="title">RESERVES</h3>
            <div className="grid">
              {currencyData.map((reserve, index) => {
                return <ReserveCard data={reserve} key={index} />;
              })}
            </div>
          </div>
        )}
        {historyIsLoading ? (
          <ActivityIndicator />
        ) : (
          <>
            {historique.filter((transactions) => transactions.isValidate)
              .length > 0 && (
              <div className="home-historique-container">
                <h3 className="title">Les dernières transactions validées</h3>
                {historique
                  .filter((transactions) => transactions.isValidate)
                  .slice(0, 5)
                  .map((h) => {
                    return (
                      <HomeHistorique data={h} green={"validée"} key={h._id} />
                    );
                  })}
              </div>
            )}{" "}
            {historique.filter((transactions) => !transactions.isValidate)
              .length > 0 && (
              <div className="home-historique-container">
                <h3 className="title">Les dernières transactions en attente</h3>
                {historique
                  .filter((transactions) => !transactions.isValidate)
                  .slice(0, 5)
                  .map((h) => {
                    return (
                      <HomeHistorique data={h} red={"en attente"} key={h._id} />
                    );
                  })}
              </div>
            )}
          </>
        )}
        {commentData.length > 0 && (
          <>
            <h3 className="title">Les derniers commentaires:</h3>
            <div className="grid-wrap">
              {items?.map((comment) => {
                return (
                  <CommentCard isHome={true} data={comment} key={comment._id} />
                );
              })}
            </div>
            <Pagination
              data={commentData}
              totalCount={commentData.length}
              setItems={setItems}
              className="pagination-bar"
              currentPage={currentPage}
              pageSize={3}
              onPageChange={(page) => setCurrentPage(page)}
            />
            <NavLink to="/commentaires" className="nav-link">
              Voir plus de commentaires
            </NavLink>
          </>
        )}
      </div>
    </div>
  );
};

export default Home;
